<script lang="ts">
	import Spinner from 'src/components/common/spinner.svelte';
	import { GetTeamScore } from 'src/graphql-operations';
	import userStore from 'src/stores/user';
	import SectionsProgress from './sections-progress.svelte';

	const score = GetTeamScore({
		variables: { team_id: userStore.getSelectedProjectId() }
	});
</script>

<div class="flex flex-col w-full">
	<div class="flex justify-between items-center">
		<span class="font-semibold text-2xl text-neutral-700"> Projeto </span>

		<a href="/project">
			<button class="button text-think-500"> Ver Projeto </button>
		</a>
	</div>

	<div class="mt-4">
		<SectionsProgress />
	</div>

	<div class="flex flex-row items-center py-4">
		{#if $score.loading}
			<Spinner size="2rem" color="neutral-700" />
		{:else if $score.data}
			<img src="/img/overview/rocket.svg" class="h-10" alt="Foguete" />
			<span class="ml-2 font-semibold text-neutral-700 text-lg">
				{$score.data.tools_aggregate.aggregate.sum.points || '0'} pontos adquiridos
			</span>
		{/if}
	</div>
</div>
