<script lang="ts">
	import Spinner from 'src/components/common/spinner.svelte';
	import { GetSectionsBasic } from 'src/graphql-operations';
	import userStore from 'src/stores/user';
	import SectionProgressCard from 'src/components/common/content/section-progress-card.svelte';

	const sections = GetSectionsBasic({
		variables: { team_id: userStore.getSelectedProjectId() }
	});
</script>

<div class="flex w-full justify-center">
	{#if $sections.loading}
		<Spinner size="12rem" color="neutral-700" />
	{:else if $sections.data}
		<div
			class="flex flex-col lg:flex-row lg:space-x-5 space-y-5 lg:space-y-0 justify-center w-full"
		>
			{#each $sections.data.sections as section}
				<div class="lg:w-1/3 h-[234px]">
					<SectionProgressCard {section} orientation={'ROW'} />
				</div>
			{/each}
		</div>
	{/if}
</div>
