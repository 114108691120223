<script>
	import YoutubeEmbedCard from 'src/components/common/multimedia/youtube-embed-card.svelte';
	import Spinner from 'src/components/common/spinner.svelte';
	import { GetVideosByTeam } from 'src/graphql-operations';
	import userStore from 'src/stores/user';

	const videos = GetVideosByTeam({
		variables: {
			team_id: userStore.getSelectedProjectId(),
			limit: 3,
			offset: 0,
			order: 'desc',
			contains_text: ''
		}
	});
</script>

<div class="flex w-full justify-between items-center">
	<div class="flex flex-col w-1/2">
		<h1 class="font-semibold text-gray-brand-500 text-2xl">Últimos vídeos</h1>
		<span class="text-gray-brand-500 mt-3">
			Confira os vídeos sugeridos para essa semana ou clique em ver todos para consultar todos os
			vídeos da plataforma.
		</span>
	</div>
	<a href="/videos">
		<button class="button text-think-500"> Ver todos os vídeos </button>
	</a>
</div>

{#if !$videos.loading && $videos.data}
	<div class="grid grid-cols-1 grid-rows-3 lg:grid-rows-1 lg:grid-cols-3 gap-4 mt-4">
		{#each $videos.data.videos as video}
			<YoutubeEmbedCard
				videoUrl={video.link}
				videoTitle={video.title}
				thumbnail={video.thumbnail}
				videoDescription={video.description}
				isCard={true}
			/>
		{/each}
	</div>
{:else}
	<div class="flex items-center justify-center">
		<Spinner color={'neutral-500'} size={'4rem'} />
	</div>
{/if}
