<div class="flex flex-1 flex-col space-y-5 py-10 px-14 bg-[#0B2465] rounded-t-3xl ">
	<div class="flex flex-col">
		<div
			class="flex md:flex-row flex-col md:items-start items-center justify-evenly space-y-6 md:space-y-0"
		>
			<div class=" flex flex-col items-center md:items-start space-y-8">
				<div class="w-[200px]">
					<a href="https://sc.acate.com.br/miditec">
						<img src="/img/index/footer/miditec_white.png" alt="" /></a
					>
				</div>
				<div>
					<p class="text-neutral-300">Copyright © TXM Business - 2019-2029</p>
				</div>
			</div>
			<div class="flex flex-col space-y-4 items-center ">
				<p class="text-neutral-300">Contato</p>
				<div class="flex flex-row space-x-4">
					<a href="mailto:contato@txm-methods.com">
						<img src="/img/index/footer/icon_mail.png" alt="" /></a
					>
					<!-- <img src="/img/index/footer/icon_whatsapp.png" alt="" /> -->
				</div>
			</div>
			<div class="flex flex-col space-y-4 items-center ">
				<p class="text-neutral-300">Redes Sociais</p>
				<div class="flex flex-row  space-x-4">
					<a href="https://instagram.com/txm.methods">
						<img src="/img/index/footer/icon_insta.png" alt="" /></a
					>
					<a href="https://www.facebook.com/TXM-Methods-112217004453869">
						<img src="/img/index/footer/icon_facebook.png" alt="" /></a
					>
					<a href="https://br.linkedin.com/company/txm-metodhs-ltd">
						<img src="/img/index/footer/icon_linkedin.png" alt="" />
					</a>
				</div>
			</div>
			<div class=" flex flex-col space-y-4 items-center">
				<p class="   text-neutral-300">Política de Privacidade</p>
				<a href="/lgpd/privacy"><img src="/img/index/footer/icon_page.png" alt="" /></a>
			</div>
		</div>
	</div>
</div>
