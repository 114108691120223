<script lang="ts">
	import OutputsSearchbar from './outputs-searchbar.svelte';
	import Spinner from 'src/components/common/spinner.svelte';
	import { GetSectionsBasic } from 'src/graphql-operations';
	import userStore from 'src/stores/user';
	import SectionDropdown from './section-dropdown.svelte';

	const sections = GetSectionsBasic({
		variables: { team_id: userStore.getSelectedProjectId() }
	});
</script>

<div class="flex w-full h-full">
	<div class="flex flex-col w-full">
		<span class="py-3 font-semibold text-2xl text-neutral-700"> Metodologia </span>

		<div class="flex flex-col md:flex-row w-full justify-between">
			<div class="flex flex-col md:w-1/2">
				<span class="py-3 font-bold text-neutral-800 text-4xl">
					Qual etapa do <span class="text-think-500">seu negócio</span>
					você quer desenvolver hoje?
				</span>
				<span class="text-neutral-700 text-lg">
					Caso não saiba por onde começar, consulte nossa metodologia completa para conhecer todo o
					material da TXM Business.
				</span>
			</div>
			<a href="/methodology">
				<button class="button text-think-500 self-start mt-4 md:mt-0"> Ver Metodologia </button>
			</a>
		</div>

		<div class="w-1/3 my-3">
			<OutputsSearchbar />
		</div>

		<div class="flex w-full justify-center mt-4">
			{#if $sections.loading}
				<Spinner size="12rem" color="neutral-700" />
			{:else if $sections.data}
				<div class="flex flex-col lg:flex-row space-y-3 lg:space-y-0 justify-center w-full">
					{#each $sections.data.sections as section}
						<SectionDropdown {section} />
					{/each}
				</div>
			{/if}
		</div>
	</div>
</div>
