<script lang="ts">
	import userStore from 'src/stores/user';

	async function login() {
		await userStore.loginWithPopup();
	}
</script>

<header class="lg:absolute w-full z-20">
	<div class="p-8 flex flex-row items-center justify-between container mx-auto">
		<div class="w-[200px]">
			<a href="https://sc.acate.com.br/miditec">
				<img src="/img/index/header/miditec_long.png" alt="" />
			</a>
		</div>
		<button class="button px-10 text-think-500" on:click={login}>Login</button>
	</div>
</header>
