<script>
	import Events from './events/events.svelte';
	import Progress from './progress/progress.svelte';
	import SectionsNav from './sections-nav/sections-nav.svelte';
	import Videos from './videos/videos.svelte';
	import Footer from '../../footer.svelte';
	import Header from '../../header.svelte';
	import Navbar from '../../navbar.svelte';
</script>

<div class="default-content-width">
	<Header />
	<Navbar />
	<Progress />

	<div class="mt-20">
		<Events />
	</div>

	<div class="mt-20">
		<Videos />
	</div>

	<div class="mt-20">
		<SectionsNav />
	</div>
	<footer>
		<Footer />
	</footer>
</div>
