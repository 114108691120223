<script lang="ts">
	import userStore from 'src/stores/user';

	async function login() {
		await userStore.loginWithPopup();
	}

	function message() {
		console.warn('TODO: Message');
	}
</script>

<div class="w-full h-full mt-10">
	<div class="flex flex-col-reverse lg:flex-row default-content-width items-center mx-auto">
		<div class="flex flex-col lg:w-2/3 p-10">
			<div class="container lg:p-10 items-center lg:items-start">
				<h1 class="title text-xl lg:text-3xl">Já faz parte da TXM Methods?</h1>
				<button class="button text-think-500" on:click={login}>Fazer seu Login</button>
			</div>

			<div class="container lg:p-10 items-center lg:items-start">
				<h1 class="title text-xl lg:text-3xl">Quer fazer parte?</h1>
				<button class="button text-think-500" on:click={message}>Mande uma Mensagem</button>
			</div>
		</div>

		<img class="w-1/4" alt="Smartphone" src="/img/index/smartphone.png" />
	</div>
</div>

<style>
	.container {
		@apply flex flex-col w-full;
	}
	.title {
		@apply font-semibold text-neutral-700 py-4;
	}
</style>
