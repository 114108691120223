<script>
	const testimonials = [
		{
			name: 'Marcos Buson',
			img: '/img/index/testimonials/buson.jpeg',
			company: 'Fundador da HARDS e Sócio da MOA Ventures',
			testimonial:
				'O Cocreation Lab by TXM possui a melhor metodologia de suporte a empreendedores na fase de ideação de negócios do Brasil. O time da TXM juntamente com sua plataforma de controle são fundamentais para qualquer processo de validação e lançamento de novos produtos do mercado. Recomendo, apoio e sou mentor do programa por acreditar no seu impacto no ecossistema de inovação.'
		},
		{
			name: 'Felipe Mandawalli',
			img: '/img/index/testimonials/felipe.png',
			company: ' ',
			testimonial:
				'Acompanho há anos o trabalho da TXM e é evidente o quanto eles vêm desempenhando um papel fundamental no ecossistema brasileiro de inovação, qualificando empreendedores, introduzindo negócios mais inovadores e competitivos no mercado, de startups à empresas tradicionais repaginadas e mais criativas, por meio de uma metodologia muito consistente e embasada cientificamente, com um DNA próprio que é o grande diferencial para outros hubs de inovação.'
		},
		{
			name: 'Odolir Daniel dos Santos Junior',
			img: '/img/index/testimonials/odolir.jpg',
			company: 'Exparticipante, mentor e Fundador do You Do',
			testimonial:
				'O Cocreation Lab foi um grande passo e parceiro na história do You Do e na minha como empreendedor. Posso dizer que uma boa parte da minha evolução pessoal se deu pelas mentorias do programa e pela metodologia aplicada. Entrar no Cocreation pode ser a girada de chave que o seu negócio precisa.'
		}
	];

	let currentPage = 0;
	function select(pageNumber) {
		currentPage = pageNumber;
	}
	function prev() {
		if (currentPage > 0) currentPage--;
		else currentPage = testimonials.length - 1;
	}
	function next() {
		if (currentPage < testimonials.length - 1) currentPage++;
		else currentPage = 0;
	}
</script>

<div class="flex lg:w-9/12 h-full mt-24 mx-auto">
	<div class="inline-flex m-auto w-full h-full items-center">
		<span on:click={prev} class="button-page">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-12 lg:w-14"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M15 19l-7-7 7-7" />
			</svg>
		</span>

		{#each testimonials as testimonial, i}
			<div
				class="flex flex-col lg:flex-row w-full h-full items-center justify-center {i != currentPage
					? 'hidden'
					: ''}"
			>
				<div class="flex flex-col lg:w-2/5 items-center justify-center">
					<img
						class="lg:w-1/2 rounded-full border-4 border-think-500"
						alt={testimonial.name}
						src={testimonial.img}
					/>
					<div class="inline-flex m-auto items-center">
						{#each testimonials as _, j}
							<div
								class="rounded-full w-3 h-3 mx-1 my-2 bg-neutral-400
                                {i == j ? 'selected-page' : ''}"
							/>
						{/each}
					</div>
				</div>

				<div class="flex flex-col  lg:w-3/5">
					<span class=" text-center lg:text-left text-3xl font-bold text-think-500"
						>{testimonial.name}</span
					>
					<span class=" text-center lg:text-left mt-4 text-xl font-medium"
						>{testimonial.company}</span
					>
					<span class=" text-left lg:text-left mt-8 text-xl font-semibold"
						>{testimonial.testimonial}</span
					>
				</div>
			</div>
		{/each}

		<span on:click={next} class="button-page">
			<svg
				xmlns="http://www.w3.org/2000/svg"
				class="h-12 lg:w-14"
				fill="none"
				viewBox="0 0 24 24"
				stroke="currentColor"
				stroke-width="2"
			>
				<path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
			</svg>
		</span>
	</div>
</div>

<style>
	.button-page {
		@apply px-10 py-8 text-neutral-600 cursor-pointer;
	}
	.selected-page {
		@apply w-4 h-4 bg-think-500;
	}
</style>
