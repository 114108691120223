<script lang="ts">
	import Calendar from './calendar.svelte';
	// import News from './news.svelte';
	import { GetLocationsCalendars } from 'src/graphql-operations';
	import userStore from 'src/stores/user';

	const locations = GetLocationsCalendars({
		variables: { team_id: userStore.getSelectedProjectId() }
	});
	let calendarId;
</script>

<div class="flex flex-col md:flex-row w-full space-y-8 md:space-y-0 md:space-x-16 xl:space-x-32">
	<div class="flex flex-col md:w-1/2">
		<div class="w-full">
			<h1 class="font-semibold text-2xl text-neutral-700 mb-2">Agenda da semana</h1>
			<span class="text-neutral-700">
				Confira os eventos mais importantes que acontecem essa semana escolhendo a região de sua
				preferência.
			</span>

			{#if !$locations.loading}
				<select
					class="mt-5 w-full bg-neutral-200/50 text-neutral-500 rounded-md px-4 py-2 border-none"
					bind:value={calendarId}
				>
					{#each $locations.data.all as location}
						<option
							value={location.calendar_id}
							selected={location.calendar_id == $locations.data.default[0]?.calendar_id}
						>
							{location.names[0].name}
						</option>
					{/each}
				</select>
			{/if}

			<div class="flex flex-row w-full mt-6 space-x-8">
				<a
					target="_blank"
					class="calendar-button"
					href="https://calendar.google.com/calendar/u/0/embed?src={calendarId}"
				>
					Agenda do Polo
				</a>
				<a
					target="_blank"
					class="calendar-button"
					href="https://calendar.google.com/calendar/u/0/embed?src={import.meta.env
						.VITE_GOOGLE_CALENDAR_GENERAL_ID}"
				>
					Agenda Geral
				</a>
			</div>
		</div>

		<!-- <div class="w-full">
			<News />
		</div> -->
	</div>

	<div class="md:w-1/2">
		<Calendar {calendarId} />
	</div>
</div>

<style>
	.calendar-button {
		background: #ffffff;
		border-radius: 6px;
		padding: 0.5rem 1rem;
		color: #7e8a9a;
	}
</style>
