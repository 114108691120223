<div class="relative container mx-auto">
	<div class="flex hero pb-14 lg:pt-32 lg:bg-[url('/img/index/hero-bg.svg')] items-center z-10">
		<div class="max-w-[84rem] p-8">
			<div class="flex flex-col text-center md:text-left">
				<span class=" text-xl md:text-5xl font-bold leading-tight text-gray-700">
					Uma metodologia <span class="text-think-500">exclusiva</span><br />
					aplicada para o desenvolvimento<br />de
					<span class="text-think-500">ideias e negócios.</span>
				</span>
				<span class="mt-4 text-lg md:text-2xl font-light">
					Desde a pré-incubação até a aceleração de novas empresas.
				</span>
				<div class="mt-16 flex-row items-center hidden md:flex">
					<img src="/img/index/mouse.svg" alt="Scroll" />
					<h1 class="text-2xl font-semibold text-neutral-500 mx-6">Saiba mais sobre a TXM</h1>
				</div>
			</div>
		</div>
	</div>

	<div class="hidden lg:block absolute top-0 left-0 w-full h-full z-0">
		<picture class="absolute w-[1000px] h-[1000px] right-[-22%] top-[-25%]">
			<source srcset="/img/index/hero-bg/blur-cyan.webp" type="image/webp" />
			<source srcset="/img/index/hero-bg/blur-cyan.png" type="image/png" />
			<img class="relative w-full h-full" src="/img/index/hero-bg/blur-cyan.png" />
		</picture>
		<picture class="absolute w-[1000px] h-[1000px] right-[-5%] top-[5%]">
			<source srcset="/img/index/hero-bg/blur-blue.webp" type="image/webp" />
			<source srcset="/img/index/hero-bg/blur-blue.png" type="image/png" />
			<img class="relative w-full h-full" src="/img/index/hero-bg/blur-blue.png" />
		</picture>
	</div>
</div>

<style>
	.hero {
		position: relative;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: right;
		width: 100%;
	}

	.blur {
		position: absolute;
		width: 60rem;
		height: 30rem;
		filter: blur(10rem);
	}
</style>
