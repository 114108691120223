<div class="flex w-full mx-auto container">
	<div
		class="flex flex-col rounded-[2.5rem] shadow-full mx-8 items-center z-[1]
        bg-neutral-100/30 border-2 border-neutral-50/30"
	>
		<span
			class="xl:text-4xl xl:font-bold md:text-2xl text-base font-semibold
            text-center p-12"
		>
			Nossa <span class="text-think-500">metodologia</span> foi pensada para
			<span class="text-think-500">desenvolver o empreendedorismo</span>
			com foco na <span class="text-think-500">cocriação</span> e estrutura flexível, com abordagem baseada
			em ferramentas de gestão, ux, design, comunicação e marketing.
		</span>

		<div class="relative flex mt-4 min-h-[35vw] w-full intro-bg">
			<div class="lg:w-2/5 xl:p-10 mx-6 lg:ml-14 text-center lg:text-left">
				<div class="flex flex-row items-center justify-between lg:hidden mb-5">
					<div class="relative">
						<img src="/img/sections/experience.png" alt="TXM Methods" class="" />
						<picture class="lg:hidden absolute -z-10 w-[300%] top-[-100%] left-[-100%]">
							<source srcset="/img/index/intro-bg/blur-experience.webp" type="image/webp" />
							<source srcset="/img/index/intro-bg/blur-experience.png" type="image/png" />
							<img
								class="relative w-full h-full"
								src="/img/index/intro-bg/blur-experience.png"
								alt=""
							/>
						</picture>
					</div>
					<div class="relative">
						<img src="/img/sections/manage.png" alt="TXM Methods" class="" />
						<picture class="lg:hidden absolute -z-10 w-[300%] top-[-100%] left-[-100%]">
							<source srcset="/img/index/intro-bg/blur-manage.webp" type="image/webp" />
							<source srcset="/img/index/intro-bg/blur-manage.png" type="image/png" />
							<img
								class="relative w-full h-full"
								src="/img/index/intro-bg/blur-manage.png"
								alt=""
							/>
						</picture>
					</div>
					<div class="relative">
						<img src="/img/sections/think.png" alt="TXM Methods" class="" />
						<picture class="lg:hidden absolute -z-10 w-[300%] top-[-100%] left-[-100%]">
							<source srcset="/img/index/intro-bg/blur-think.webp" type="image/webp" />
							<source srcset="/img/index/intro-bg/blur-think.png" type="image/png" />
							<img class="relative w-full h-full" src="/img/index/intro-bg/blur-think.png" alt="" />
						</picture>
					</div>
				</div>
				<span class="xl:text-2xl xl:font-semibold md:text-lg text-base font-medium">
					A TXM Business possui três etapas:
					<span class="text-think-500">Think</span>,
					<span class="text-experience-500">EXperience</span>
					e <span class="text-manage-500">Manage</span>.<br />
					Sua abordagem é não-linear, ou seja, é possível iniciar utilizando as ferramentas mais adequadas
					a cada projeto, voltar e avançar de acordo com o desenvolvimento do negócio.
				</span>
			</div>
			<picture class="hidden lg:block absolute -z-10 w-[70%] left-[22%] top-[-42%]">
				<source srcset="/img/index/intro-bg/blur-think.webp" type="image/webp" />
				<source srcset="/img/index/intro-bg/blur-think.png" type="image/png" />
				<img class="relative w-full h-full" src="/img/index/intro-bg/blur-think.png" alt="" />
			</picture>
			<picture class="hidden lg:block absolute -z-10 w-[70%] left-[50%] top-[-11%]">
				<source srcset="/img/index/intro-bg/blur-experience.webp" type="image/webp" />
				<source srcset="/img/index/intro-bg/blur-experience.png" type="image/png" />
				<img class="relative w-full h-full" src="/img/index/intro-bg/blur-experience.png" alt="" />
			</picture>
			<picture class="hidden lg:block absolute -z-10 w-[70%] left-0 top-0">
				<source srcset="/img/index/intro-bg/blur-manage.webp" type="image/webp" />
				<source srcset="/img/index/intro-bg/blur-manage.png" type="image/png" />
				<img class="relative w-full h-full" src="/img/index/intro-bg/blur-manage.png" alt="" />
			</picture>
		</div>

		<div class="info-container mt-8 lg:mt-20 flex-col lg:flex-row">
			<div class="info-logo-container lg:w-2/5">
				<img src="/img/index/txm-logo.svg" alt="TXM Methods" class="w-2/3" />
				<a
					class="button info-logo-button lg:w-2/3"
					target="_blank"
					rel="noopener noreferrer"
					href="https://txm-methods.com"
				>
					Conheça a TXM Methods
				</a>
			</div>
			<div class="py-4 lg:w-3/5">
				<h1 class="text-4xl font-bold text-center lg:text-left mb-5">A TXM Methods</h1>
				<span class="text-xl font-bold leading-relaxed">
					A metodologia foi desenvolvida pela TXM Methods, uma empresa de desenvolvimento de métodos
					e processos para auxiilar empreendedores, organizações públicas e privadas, e instituições
					acadêmicas a inovarem.
				</span>
			</div>
		</div>

		<div class="info-container mb-10 flex-col lg:flex-row">
			<div class="info-logo-container lg:w-2/5">
				<img src="/img/index/cocreation-logo.svg" alt="TXM Methods" class="w-2/3" />
				<a
					class="button info-logo-button lg:w-2/3"
					target="_blank"
					rel="noopener noreferrer"
					href="https://cocreationlab.com.br/"
				>
					Conheça o Cocreation Lab
				</a>
			</div>
			<div class="py-4 lg:w-3/5">
				<h1 class="text-4xl font-bold text-center lg:text-left mb-5">Laboratórios</h1>
				<span class="text-xl font-bold leading-relaxed">
					Nossos métodos são aplicados no maior laboratório de ideação do país, o Cocreation lab,
					que auxilia pessoas físicas a construírem um novo negócio.
				</span>
			</div>
		</div>
	</div>
</div>

<style>
	.shadow-full {
		box-shadow: 5px 5px 18px rgba(0, 0, 0, 0.1), inset 2px 6px 27px rgba(0, 0, 0, 0.1);
	}
	@media (min-width: 1280px) {
		.intro-bg {
			background-image: url('/img/index/intro-bg.svg');
			background-size: contain;
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.info-container {
		@apply flex w-full p-10 text-neutral-700;
	}
	.info-logo-container {
		@apply flex flex-col self-center  p-4 items-center;
	}
	.info-logo-button {
		@apply text-lg  mt-6;
	}
</style>
