<script lang="ts">
	import Spinner from 'src/components/common/spinner.svelte';
	import { GetOutputsDoneBySection } from 'src/graphql-operations';
	import type { GetSectionsBasicQuery } from 'src/graphql-operations';
	import userStore from 'src/stores/user';
	import { slide } from 'svelte/transition';

	export let section: GetSectionsBasicQuery['sections'][0];

	function handleDropdown() {
		dropdown = !dropdown;
	}

	let dropdown = false;
	const outputs = GetOutputsDoneBySection({
		variables: { section_id: section.id, team_id: userStore.getSelectedProjectId() }
	});
</script>

{#if $outputs.loading}
	<Spinner size="2rem" color="neutral-700" />
{:else if $outputs.data}
	<div class="flex flex-col w-full mx-1 items-center">
		<!-- Section card -->
		<div class="card flex-col w-full p-3 mb-1 cursor-pointer" on:click={handleDropdown}>
			<div class="flex flex-row w-full items-start justify-between my-3">
				<div class="flex flex-row items-start w-[85%]">
					<figure class="h-[62px] w-[62px] flex-shrink-0">
						<img
							src="/img{section.section_icons_files.find((icon) => icon.type === 'medium').file
								.path}"
							alt={section.name}
							class="max-w-[62px] max-h-[62px]"
						/>
					</figure>
					<div>
						<span class="font-semibold text-2xl" style="color: var(--color-{section.slug}-700)">
							{section.name}
						</span>
						<span class="text-neutral-700 line-clamp-3">
							{section.intro}
						</span>
					</div>
				</div>

				{#if !dropdown}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-6 w-6 text-neutral-500 mx-4"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						stroke-width="2"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
					</svg>
				{:else}
					<svg
						xmlns="http://www.w3.org/2000/svg"
						class="h-6 w-6 text-neutral-500 mx-4"
						fill="none"
						viewBox="0 0 24 24"
						stroke="currentColor"
						stroke-width="2"
					>
						<path stroke-linecap="round" stroke-linejoin="round" d="M5 15l7-7 7 7" />
					</svg>
				{/if}
			</div>

			<div class="flex flex-col self-end mr-3">
				<span class="text-xs text-neutral-500 font-bold mr-10 mb-1 ml-1">
					{$outputs.data.outputs.filter((o) => o.done.aggregate.count == o.all.aggregate.count)
						.length}
					de
					{$outputs.data.outputs.length}
					Outputs concluídos
				</span>
				<div class="w-full bg-[#C4C4C4] rounded-full h-[6px]">
					<div
						class="h-[6px] rounded-full"
						style="width: {($outputs.data.outputs.filter(
							(o) => o.done.aggregate.count == o.all.aggregate.count
						).length /
							$outputs.data.outputs.length) *
							100}%;
                            background-color: var(--color-{section.slug}-500)"
					/>
				</div>
			</div>
		</div>

		<!-- Outputs cards -->
		{#each dropdown ? $outputs.data.outputs : [] as output, i}
			<a
				href="/{section.slug}/{output.slug}"
				class="w-[95%]"
				transition:slide|local={{ delay: i * 20 }}
			>
				<div class="card px-5 py-6 my-1 cursor-pointer mx-auto output-card">
					<div class="flex flex-row w-full items-center justify-between">
						<div class="flex flex-row items-center flex-grow">
							<div class="h-8 w-8 flex-shrink-0">
								<img
									src="/img{output.icons.find((icon) => icon.type === 'small').file.path}"
									alt={output.name}
									class="max-w-full h-full"
								/>
							</div>
							<div class="ml-4 flex-grow">
								<span
									class="font-semibold text-xl flex flex-row"
									style="color: var(--color-{section.slug}-700)"
								>
									{output.name}

									{#if output.done.aggregate.count == output.all.aggregate.count}
										<svg
											xmlns="http://www.w3.org/2000/svg"
											class="h-6 w-6 text-green-500"
											fill="none"
											viewBox="0 0 24 24"
											stroke="currentColor"
											stroke-width="2"
										>
											<path stroke-linecap="round" stroke-linejoin="round" d="M5 13l4 4L19 7" />
										</svg>
									{/if}
								</span>
								<span class="text-neutral-700 text-sm line-clamp-3 lg:hidden xl:line-clamp-2">
									{output.intro}
								</span>
							</div>
						</div>

						<svg
							xmlns="http://www.w3.org/2000/svg"
							class="h-6 w-6 text-neutral-700 ml-4 flex-shrink-0"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor"
							stroke-width="2"
						>
							<path stroke-linecap="round" stroke-linejoin="round" d="M9 5l7 7-7 7" />
						</svg>
					</div>
				</div>
			</a>
		{/each}
	</div>
{/if}

<style>
	.card {
		border-radius: 15px;
		box-shadow: -4px -5px 13px #ffffff, 5px 7px 16px rgba(0, 0, 0, 0.25);
	}

	.card:hover {
		box-shadow: -4px -5px 8px #ffffff, 2px 4px 8px rgba(0, 0, 0, 0.25);
	}
</style>
