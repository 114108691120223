<script>
    let search;
    console.warn("TODO: Sections searchbar");
    // $: {
    //     console.log(search);
    // }
</script>


<!-- <input bind:value={search} type="search"
    class="input w-full"
    placeholder="Digite aqui para buscar por ferramenta" /> -->

<style>
    .input {
        padding: 0.5rem 1rem;
    }
</style>